import type { Variation } from 'src/components/product-page/Selector/types'
import './styles.scss'

interface Props {
  variation: Variation
  onClickVariation: (name: any, value: string) => void
}

export function DefaultSelector({ variation, onClickVariation }: Props) {
  const variationName = variation.field.name

  const sortedVariationValues = [...variation.values].sort(
    (a, b) => a.position - b.position
  )

  const keywordsHiddenComponent = [
    'ÚNICO',
    'UNICO',
    'Único',
    'ÚNICA',
    'UNICA',
    'Única',
    'U',
    ',',
    '.',
    'B',
    'N/A',
    'UN',
    'variado',
    'Sem sabor',
    'SEM-SABOR',
    '3953',
    '3954',
  ]

  if (
    sortedVariationValues.length <= 1 &&
    keywordsHiddenComponent.includes(sortedVariationValues[0].name)
  ) {
    return null
  }

  return (
    <div className="">
      <div className="flex items-center justify-between">
        <span className="text-deepGray font-bold font-roboto text-sm">
          {variationName}:
        </span>

        {variationName === 'Tamanho' && (
          <a
            href="/guia-de-tamanhos"
            target="_blank"
            className="text-sm font-roboto underline text-deepGray cursor-pointer flex items-center gap-1"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="15"
              viewBox="0 0 16 15"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.0713 3.56202L11.7713 0.262024C11.5109 0.00176984 11.0889 0.00176984 10.8286 0.262024L0.928589 10.162C0.668335 10.4224 0.668335 10.8444 0.928589 11.1047L4.22859 14.4047C4.48892 14.6649 4.91092 14.6649 5.17126 14.4047L15.0713 4.50469C15.3315 4.24436 15.3315 3.82236 15.0713 3.56202ZM2.34333 10.6334L4.22867 8.74735L5.17133 9.69135L6.11467 8.74802L5.17133 7.80535L6.586 6.39135L8 7.80535L8.94267 6.86135L7.52867 5.44802L8.94267 4.03335L9.88533 4.97602L10.8287 4.03335L9.88533 3.09069L11.3 1.67669L13.6567 4.03335L4.7 12.99L2.34333 10.6334Z"
                fill="#4E5D6B"
              />
            </svg>
            Tabela de tamanhos
          </a>
        )}
      </div>

      <ul
        className="flex items-center gap-[12px] flex-wrap mt-3"
        key={variation.field.id}
      >
        {sortedVariationValues.map((variationValue) => (
          <li key={variationValue.id}>
            <button
              disabled={!variationValue.isAvailable}
              className={`sku-selection-button relative text-sm font-roboto rounded-md px-[12px] py-2 border hover:border-[#007DBC] hover:text-[#007DBC] ${
                variationValue.selected
                  ? 'border-[#007DBC] text-[#007DBC]'
                  : 'border-gray text-darkGray'
              } ${
                variationValue.isAvailable
                  ? 'isAvailable'
                  : 'hover:border-gray cursor-not-allowed'
              }`}
              onClick={() =>
                onClickVariation(variationName, variationValue.name)
              }
            >
              <span> {variationValue.name} </span>
            </button>
          </li>
        ))}
      </ul>
    </div>
  )
}
