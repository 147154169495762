import { useCallback, useEffect, useMemo, useState } from 'react'
import type {
  Variation,
  SelectedVariation,
  SkuSpecificationsName,
  ProductItem,
} from 'src/components/product-page/Selector/types'
import type {
  Product,
  SkuSpecification,
} from 'src/components/product-page/context/types/product.type'

import { RenderedSelectors } from './RenderedSelectors'

type Props = {
  product: Product
  itemsSku: ProductItem[]
  skuSpecifications: SkuSpecification[]
  initialSku: ProductItem
  setCurrentSku: (currentSku: ProductItem) => void
}

function verifyItemIsAvailable(productItem: ProductItem) {
  const sellerDefault =
    productItem.sellers.find((item) => item.sellerDefault) ??
    productItem.sellers[0]

  return sellerDefault.commertialOffer.IsAvailable
}

const getSelectedValues = (specifications: Variation[]) => {
  return specifications
    .filter((variation) => variation.selected)
    .map((variation) => ({
      field: variation.field.name,
      value: variation.values.find((value) => value.selected)?.name as string,
    }))
}

const checkSkuIsAvailable = (
  [...selectedVariation]: SelectedVariation[],
  itemsSku: ProductItem[]
) => {
  return (
    itemsSku.find((productItem) =>
      selectedVariation.every((variation) => {
        return (
          productItem[variation.field][0] === variation.value &&
          verifyItemIsAvailable(productItem)
        )
      })
    ) !== undefined
  )
}

export function SkuSelector({
  product,
  itemsSku,
  skuSpecifications,
  initialSku,
  setCurrentSku,
}: Props) {
  const [variations, setVariations] = useState<Variation[]>([])

  const skuSpecificationsActive = useMemo(() => {
    if (product.productId === '11113') {
      const skuSpecificationsGiftCard = skuSpecifications.filter(
        (skuSpecification) => skuSpecification.field.name === 'Valor'
      )

      return skuSpecificationsGiftCard
        .filter(
          (item) => item.field.name !== ('Marketplace' as SkuSpecificationsName)
        )
        .map((specification) => {
          const valuesName = product.items.map(
            (sku) => sku[specification.field.name]?.[0]
          )

          return {
            ...specification,
            values: specification.values.filter((value) =>
              valuesName.includes(value.name)
            ),
          }
        })
    }

    return skuSpecifications
      .filter(
        (item) => item.field.name !== ('Marketplace' as SkuSpecificationsName)
      )
      .map((specification) => {
        const valuesName = product.items.map(
          (sku) => sku[specification.field.name]?.[0]
        )

        return {
          ...specification,
          values: specification.values.filter((value) =>
            valuesName.includes(value.name)
          ),
        }
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skuSpecifications, product.productId])

  const initialVariations = useMemo(
    () =>
      skuSpecificationsActive.map((variation) => {
        return {
          ...variation,
          selected: true,
          values: variation.values.map((variationValues) => ({
            ...variationValues,
            selected:
              variationValues.name === initialSku[variation.field.name][0],
            isAvailable: checkSkuIsAvailable(
              [{ field: variation.field.name, value: variationValues.name }],
              itemsSku
            ),
          })),
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [checkSkuIsAvailable]
  )

  const updateVariationsBySelectedValues = useCallback(
    (
      tempVariations: Variation[],
      selectedVariations: SelectedVariation[],
      nextVariation?: Variation
    ) => {
      const nextOriginalVariation = initialVariations.find(
        (variationItem) =>
          variationItem.field.name === nextVariation?.field.name
      )

      const currentVariationSelect = initialVariations.find(
        (variationItem) =>
          variationItem.field.name !== nextVariation?.field.name
      )

      const currentValueVariantSelect = selectedVariations.find(
        (vari) => vari.field === currentVariationSelect?.field.name
      )

      const itemsBySelectedVariations = itemsSku.filter(
        (item) =>
          item[currentVariationSelect!.field.name][0] ===
          currentValueVariantSelect!.value
      )

      const possibleValuesBySelectedVariations =
        nextOriginalVariation?.values.filter((variationValue) =>
          itemsBySelectedVariations.find(
            (productItem) =>
              productItem[nextOriginalVariation.field.name][0] ===
              variationValue.name
          )
        )

      const newNextVariation = {
        ...nextOriginalVariation,
        values: possibleValuesBySelectedVariations,
      }

      return tempVariations.map((variation) => {
        if (variation.field.name !== nextVariation?.field.name) {
          return { ...variation }
        }

        if (newNextVariation?.values?.length) {
          const oldVariantSelect = newNextVariation.values.find(
            (item) => item.selected
          )

          const values = [...newNextVariation.values]

          values.forEach((item) => {
            item.selected = false
            item.isAvailable = checkSkuIsAvailable(
              [
                currentValueVariantSelect!,
                { field: variation.field.name, value: item.name },
              ],
              itemsSku
            )
          })

          const hasExistEqualVariantInNewValues = values.find(
            (item) => item.name === oldVariantSelect?.name
          )

          if (!hasExistEqualVariantInNewValues) {
            const firstItemAvaliable = values.find((item) => item.isAvailable)

            if (firstItemAvaliable) {
              firstItemAvaliable.selected = true
            }

            return {
              ...variation,
              selected: true,
              values,
            }
          }

          hasExistEqualVariantInNewValues.selected = true

          return {
            ...variation,
            selected: true,
            values,
          }
        }

        return { ...variation }
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [checkSkuIsAvailable, initialVariations]
  )

  useEffect(() => {
    if (!initialVariations) {
      return
    }

    const [, nextVariant] = initialVariations

    const selectedValues = getSelectedValues(initialVariations)

    const validatedVariations = updateVariationsBySelectedValues(
      initialVariations,
      selectedValues,
      nextVariant
    )

    setVariations(validatedVariations)

    const productBySelectedVariations =
      itemsSku.find((item) =>
        validatedVariations.every(
          (variation) =>
            item[variation.field.name][0] ===
              variation.values.find((itemValue) => itemValue.selected)?.name &&
            verifyItemIsAvailable(item)
        )
      ) ??
      itemsSku.find((item) =>
        validatedVariations.every(
          (variation) =>
            item[variation.field.name][0] ===
            variation.values.find((itemValue) => itemValue.selected)?.name
        )
      )

    if (productBySelectedVariations) {
      setCurrentSku(productBySelectedVariations)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSelectedValues, initialVariations, updateVariationsBySelectedValues])

  const onClickVariation = (name: SkuSpecificationsName, value: string) => {
    const idxCurrentVariation = variations.findIndex(
      (variation) => variation.field.name === name
    )

    const nextVariant = initialVariations[idxCurrentVariation + 1]

    const updatedVariations = variations.map((vari, index) => {
      if (idxCurrentVariation === 0) {
        const productByCurrentVariation =
          product.items.find(
            (productItem) =>
              productItem[name][0] === value && productItem.isAvailable
          ) ??
          (product.items.find(
            (productItem) =>
              productItem[name][0] === value && !productItem.isAvailable
          ) as ProductItem)

        setCurrentSku(productByCurrentVariation)
      }

      if (idxCurrentVariation === index) {
        return {
          ...vari,
          selected: true,
          values: vari.values.map((item) => ({
            ...item,
            selected: item.name === value,
          })),
        }
      }

      if (idxCurrentVariation < index) {
        return {
          ...vari,
          selected: false,
          values: vari.values.map((item) => ({ ...item, selected: false })),
        }
      }

      if (idxCurrentVariation > index) {
        return { ...vari }
      }

      return { ...vari }
    })

    const selectedValues = getSelectedValues(updatedVariations)

    const validatedVariations = updateVariationsBySelectedValues(
      updatedVariations,
      selectedValues,
      nextVariant
    )

    setVariations(validatedVariations)

    const productBySelectedVariations =
      itemsSku.find((item) =>
        validatedVariations.every(
          (variation) =>
            item[variation.field.name][0] ===
              variation.values.find((itemValue) => itemValue.selected)?.name &&
            verifyItemIsAvailable(item)
        )
      ) ??
      itemsSku.find((item) =>
        validatedVariations.every(
          (variation) =>
            item[variation.field.name][0] ===
            variation.values.find((itemValue) => itemValue.selected)?.name
        )
      )

    if (productBySelectedVariations) {
      setCurrentSku(productBySelectedVariations)
    }
  }

  return (
    <>
      <RenderedSelectors
        variations={variations}
        onClickVariation={onClickVariation}
        product={product}
      />
    </>
  )
}
